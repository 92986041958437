import React, { useState } from 'react';
import { AppBar, makeStyles, Typography, Tabs, Tab, Menu, MenuItem, Link as MuiLink } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { connect } from 'react-redux';
import { formatMoney } from '../util/money';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { bottomBorder, topBorder } from '../styles/common';
import { actOpenAddFunds } from '../redux/backend/add-funds';
// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "../redux/auth"
import { auth0Cfg } from '../config/auth0';
import ResetPasswordDialog from './ResetPasswordDialog';
import './css/FaqPage.css';
import MobileDrawerMenu from './mobileMenu/mobileDrawerMenu';


export const topBarHeight = 56;

const mainStyles = makeStyles((theme) => ({
    appBarContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...bottomBorder(theme),
    },
    appTitle: {
        paddingLeft: theme.spacing(3),
        width: 350,
        fontSize: 24,
        fontWeight: 650,
        cursor: 'pointer',
    },
    tabContainer: {
        width: 680, //sync with tab minWidth
    },
    userPanel: {
        width: 350,
        textAlign: 'right',
        paddingRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        opacity: 0.7,
    },
    accountBalance: {
        fontWeight: 600,
        fontSize: 16,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(3),
        color: theme.palette.grey[900],
        textDecoration: 'none',
        cursor: 'pointer',
        outline: 'none',
    },
    userIcon: {
        cursor: 'pointer',
    },
    menuPaper: {
        '& li': {
            fontSize: 14,
        },
    },
    addFundsMenuItem: {
        color: theme.palette.primary.main,
        ...bottomBorder(theme),
    },
    changePasswordMenuItem: {
        ...topBorder(theme),
    },
    actionText: {
        marginLeft: '20px',
        fontFamily: 'SF Pro Medium',
        fontSize: 20,
        color: '#0da12f',
        fontSize: 14,
        letterSpacing: 0,
        fontWeight: 500
    },
}));

export const tabStyles = makeStyles((theme) => ({
    root: {
        minWidth: 100,
        width: 100,
        height: topBarHeight,
        textTransform: 'none',
        color: theme.palette.text.secondary,
        '&$selected': {
            '& *': {
                color: theme.palette.secondary.main,
            },
        },
        '&:hover': {
            '& $wrapper': {
                color: theme.palette.secondary.main,
            },
        },
    },
    selected: {},
    textColorInherit: {
        opacity: 1,
    },
    wrapper: {
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 500,
    },
}));

export const shortTabStyles = makeStyles((theme) => ({
    root: {
        minWidth: 60,
        width: 60,
        height: topBarHeight,
        textTransform: 'none',
        color: theme.palette.text.secondary,
        '&$selected': {
            '& *': {
                color: theme.palette.secondary.main,
            },
        },
        '&:hover': {
            '& $wrapper': {
                color: theme.palette.secondary.main,
            },
        },
    },
    selected: {},
    textColorInherit: {
        opacity: 1,
    },
    wrapper: {
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 500,
    },
}));

const TopBar = (props) => {

    
    const classes = mainStyles();
    const tabClasses = tabStyles();
    const shortTabClasses = shortTabStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleUserIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleUserMenuClose = () => {
        setAnchorEl(null);
    };
    const handleAddFunds = () => {
        props.dispatch(actOpenAddFunds());
        setAnchorEl(null);
    };

    const { logout, user } = useAuth0();
    const handleLogout = () => {
        logout({ returnTo: auth0Cfg.logoutUrl });
    };

    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
    const handleResetPassword = () => {
        handleUserMenuClose();
        setResetPasswordOpen(true);
    };

    const location = useLocation();
    const history = useHistory();

    const onTitleClick = () => history.push('/');

    const openStatic = (url) => window.open(url, '_blank');

    function detectMob() {
        return ((window.innerWidth <= 800) && (window.innerHeight <= 600));
    }

    return (
        <>
         <div className='web'>
            <AppBar color='inherit' elevation={0} className={classes.appBar}>
                <div className={classes.appBarContent}>
                    <Typography variant="h5" className={classes.appTitle} onClick={onTitleClick}>BigMama Network</Typography>
                    <div className={classes.tabContainer}>
                        <Tabs value={location.pathname}>
                            <Tab id='tab_shop' label="Shop" classes={shortTabClasses} component={Link} value="/" to="/" />
                            {/* <Tab id='tab_ports' label="Rotating" classes={tabClasses} component={Link} value="/ports" to="/ports" /> */}
                            {/* <Tab id='tab_static' label="Static" classes={tabClasses} component={Link} value="/static" to="/static" /> */}
                            <Tab id='tab_my_proxies' label="My&nbsp;Proxies" classes={tabClasses} component={Link} value="/my" to="/my" />
                            <Tab id='tab_payments' label="Payments" classes={tabClasses} component={Link} value="/payments" to="/payments" />
                            <Tab id='tab_download' label="Downloads" classes={tabClasses} component={Link} value="/download" to="/download" />
                            <Tab id='tab_api' label="API&nbsp;" classes={shortTabClasses} component={Link} value="/api" to="/api" />
                            <Tab id='tab_faq' label="FAQ&nbsp;" classes={shortTabClasses} component={Link} value="/faq" to="/faq" />
                        </Tabs>
                    </div>
                    <div className={classes.userPanel}>
                        <MuiLink href='#' className={classes.accountBalance} onClick={handleAddFunds}>
                            {props.account ? formatMoney(props.account.balanceCents) : "..."}
                        </MuiLink>
                        <AccountCircleIcon onClick={handleUserIconClick} className={classes.userIcon} />
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleUserMenuClose}
                            classes={{ paper: classes.menuPaper }}>
                            <MenuItem disabled={true}>{props.account.email}</MenuItem>
                            <MenuItem onClick={handleAddFunds} className={classes.addFundsMenuItem}>Add Funds</MenuItem>
                            <MenuItem component={Link} value="/referral" to="/referral">Referral</MenuItem>
                            <MenuItem onClick={() => openStatic('/terms.html')}>Terms and Conditions</MenuItem>
                            <MenuItem onClick={() => openStatic('/privacy.html')}>Privacy Policy</MenuItem>
                            <MenuItem onClick={handleResetPassword} className={classes.changePasswordMenuItem}>Change Password</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </div>
                <ResetPasswordDialog open={resetPasswordOpen} onClose={() => setResetPasswordOpen(false)} user={props.account}/>
            </AppBar>
            </div>
            <div className='mobile'>
                <MobileDrawerMenu />
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    account: state.account,
});

export default connect(mapStateToProps)(TopBar);
